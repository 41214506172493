import React, { createContext } from 'react';
import './App.css';
import Canvas from './components/Canvas'
import HtmlOverlay from './components/HtmlOverlay';
import SceneSwitcher from './components/SceneSwitcher';

const Context = createContext();

global.serverURL = "https://leverstijl.local";

function App() {
  return (
    <Context.Provider value={true}>
      <Canvas />
      <HtmlOverlay />
      <SceneSwitcher />
    </Context.Provider>
  );
}

export default App;
