import React from "react";

import { Container } from 'react-bootstrap';

const SceneSwitcher = (props) => {
  return (
    <Container
      id="sceneSwitcher"
      fluid style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        zIndex: 1,
      }}>

    </Container>
  )
}
export default SceneSwitcher;