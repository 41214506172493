import $ from 'jquery'
import * as THREE from 'three'
import React, { Suspense, useMemo, useState, useRef, forwardRef } from 'react'
import { Canvas as ThreeCanvas, useFrame, useThree } from '@react-three/fiber'
import { Loader, OrbitControls, Sky } from "@react-three/drei";
import { Stage } from '@react-three/drei';

import CameraControls from 'camera-controls'

import Clicks from './Clicks'

// import het eilandje
import LiverIsland from './gltfjsx/All_Liver_Island.js'
import Huisje from './gltfjsx/House_Visitor.js'
import Bioscoop from './gltfjsx/Cinema.js'
import Ziekenhuis from './gltfjsx/Care_center.js'

CameraControls.install({ THREE })
const randomPos = (min = 5, max = -5) => Math.random() * (max - min) + min


function Controls({ zoom, focus, doanimate, pos = new THREE.Vector3(100, 100, 100), look = new THREE.Vector3(100, 100, 100) }) {
  const { camera, gl, scene } = useThree()

  // scene.addEventListener("click", function (evnt) {
  //   console.log(evnt.target);
  // });

  const debugDiv = document.querySelector('#overlayDebug')
  // var eiland = scene.getObjectByName('Turbina_Heolica001', true)
  // console.log(eiland)
  // const gl = useThree((state) => state.gl)
  const controls = useMemo(() => new CameraControls(camera, gl.domElement), [])
  return useFrame((state, delta) => {
    zoom ? pos.set(focus.x, focus.y, focus.z + 0.2) : pos.set(10, 10, 15)
    zoom ? look.set(focus.x, focus.y, focus.z - 0.2) : look.set(10, 10, 1)

    // debugDiv.innerHTML = JSON.stringify({
    //   position: state.camera.position,
    //   rotation: state.camera.rotation,
    // }, null, 2)

    if (doanimate) {
      state.camera.position.lerp(pos, 0.5)
      state.camera.updateProjectionMatrix()

      controls.setLookAt(state.camera.position.x, state.camera.position.y, state.camera.position.z, look.x, look.y, look.z, true)
    }

    return controls.update(delta)
  })
}


function RepositionCamera(_currentView) {
  const { camera, gl, scene } = useThree()
  if (_currentView == 'ziekenhuis') {
    camera.position.x = 81.4068954
    camera.position.y = 30.46976
    camera.position.z = 1.84068

    camera.rotation.x = -1.09964
    camera.rotation.y = 1.24899
    camera.rotation.z = 1.07799
    camera.updateProjectionMatrix()
  }
  return null;
}

function Animations() {
  const [cameraPosition, setCameraPosition] = React.useState(false);
  const { camera, gl, scene } = useThree()
  const debugDiv = document.querySelector('#overlayDebug')
  const wieken1 = scene.getObjectByName('Turbina_Heolica003', true)
  const wieken2 = scene.getObjectByName('Turbina_Heolica004', true)

  const reuzenradGroup = scene.getObjectByName('Reuzenrad', true)

  // billboard = Cube303_1

  // console.log(wieken2)
  return useFrame((state, delta) => {

    // if (state.camera.userData.hasOwnProperty('sceneReposition')) {
    //   switch (state.camera.userData.sceneReposition) {
    //     case 'ziekenhuis':
    //       state.camera.position.x = 81.4068954
    //       state.camera.position.y = 30.46976
    //       state.camera.position.z = 1.84068

    //       state.camera.rotation.x = -1.09964
    //       state.camera.rotation.y = 1.24899
    //       state.camera.rotation.z = 1.07799
    //       // camera.updateProjectionMatrix()
    //       delete camera.userData.sceneReposition;
    //       break;
    //   }
    // }

    if (wieken1) {
      wieken1.rotation.z += Math.PI * delta
    }
    if (wieken2) {
      wieken2.rotation.z += Math.PI * (delta / 2)
    }
    debugDiv.innerHTML = JSON.stringify({
      position: state.camera.position,
      rotation: state.camera.rotation,
      currentScene: window.currentScene
    }, null, 2);
  })
}

const Canvas = (props) => {
  window.currentScene = ''
  const [ready, setReady] = React.useState(false);
  const [repositionCamera, setRepositionCamera] = React.useState(false);

  const [showEiland, setShowEiland] = React.useState(true);
  const [showHuisje, setShowHuisje] = React.useState(false);
  const [showBios, setShowBios] = React.useState(false);
  const [showZiekenuis, setShowZiekenuis] = React.useState(false);

  // const eiland = forwardRef()
  const [zoom, setZoom] = useState(false)
  const [focus, setFocus] = useState({
    x: 1000,
    y: 1000,
    z: 1000
  })
  // const planeRef = usePlane(() => ({ ...props }));

  const ref = React.useRef();

  const onCanvasCreated = (obj) => {
    console.log("Canvas created", obj.scene)
    obj.userData = {
      importObjects: []
    }
  }

  const RepositionCamera = () => {
    const { camera, gl, controls } = useThree()
    console.log('repositionCamera ', camera)
    camera.position.x = 81.4068954
    camera.position.y = 30.46976
    camera.position.z = 1.84068

    camera.rotation.x = -1.09964
    camera.rotation.y = 1.24899
    camera.rotation.z = 1.07799
    camera.updateProjectionMatrix()
    setRepositionCamera(false)
    return null;
  };

  const onClicks = (e) => {
    e.stopPropagation()
    var click = new Clicks(e);
  }

  $("#overlayContainer").unbind();
  $("#overlayContainer").on('click', '#overlayDebug', (el) => {
    console.log('Clicked on ', el.target)
  });

  $("#overlayContainer").on('click', 'button', (e) => {
    var overlay = document.querySelector('#overlayContainer');
    var switcher = document.querySelector('#sceneSwitcher');
    e.stopPropagation()
    var _this = $(e.currentTarget);
    if (_this.attr('data-action') == "goto-huisje") {
      // fade out eiland
      overlay.style.display = 'block';
      switcher.style.opacity = 0;
      switcher.classList.add('fadeIn');
      overlay.classList.add('fadeOut');
      setTimeout(() => {
        overlay.innerHTML = "";
        setReady(false);
        setShowEiland(false);
        setShowHuisje(true);
      }, 800)
      // alert('ga naar binnen')
    }
    if (_this.attr('data-action') == "goto-bioscoop") {
      // fade out eiland
      window.currentScene = 'bioscoop'
      overlay.style.display = 'block';
      switcher.style.opacity = 0;
      switcher.classList.add('fadeIn');
      overlay.classList.add('fadeOut');
      setTimeout(() => {
        overlay.innerHTML = "";
        setReady(false);
        setShowEiland(false);
        setShowBios(true);
      }, 800)
      // alert('ga naar binnen')
    }
    if (_this.attr('data-action') == "goto-ziekenhuis") {
      // fade out eiland
      overlay.style.display = 'block';
      switcher.style.opacity = 0;
      switcher.classList.add('fadeIn');
      overlay.classList.add('fadeOut');
      setTimeout(() => {
        overlay.innerHTML = "";
        setReady(false);
        setShowEiland(false);
        setShowZiekenuis(true);
      }, 800)
      // alert('ga naar binnen')
    }
    // setShowBios
    // setShowZiekenuis
  })

  return (
    <>
      <ThreeCanvas
        ref={ref}
        mode="concurrent"
        // frameloop="demand"
        damping={false}
        shadows={false}
        flat={true}
        linear={false}
        // vr={false}
        style={{ position: 'absolute', backgroundColor: ready ? '#87CEEB' : "#171717", opacity: 0.8 }}
        camera={{
          fov: 45,
          near: 0.1,
          far: 1000,
          position: [35, 41, 25],
          rotation: [-0.776, 0.746, 0.588],
          // position: [0, 0, 5],
          userData: {
            hasDefaults: false
          }
        }}
        onCreated={onCanvasCreated}
        onUpdate={(self) => console.log("Canvas Updated  => ", self)}
        onChange={(self) => console.log("Canvas Changed  => ", self)}
      >
        {/* <fog attach="fog" args={[0xfff0ea, 20, 50]} /> */}
        <Suspense fallback={null}>
          {/* <Stage
            contactShadow={false} // Optional: creates a contactshadow underneath the content (default=true)
            shadows={false} // Optional: lights cast shadow (default=true)
            adjustCamera={false} // Optional: zooms the content in (default=true)
            intensity={0} // Optional: light intensity (default=1)
            environment="city" // Optional: environment (default=city)
            preset="rembrandt" // Optional: rembrandt (default) | portrait | upfront | soft
            shadowBias={0.0001} // Optional: shadow bias (default=0.0001)
          // controls={controlsRef} // Optional: recalculates control target for correctness
          >
            {showEiland ? <LiverIsland onUpdate={(self) => setReady(true)} onClick={onClicks} /> : null}
            {showHuisje ? <Huisje onUpdate={(self) => setReady(true)} onClick={onClicks} /> : null}
          </Stage> */}
          {showEiland ? <LiverIsland onUpdate={(self) => setReady(true)} onClick={onClicks} /> : null}
          {showHuisje ? <Huisje onUpdate={(self) => setReady(true)} onClick={onClicks} /> : null}
          {showBios ? <Bioscoop onUpdate={(self) => setReady(true)} onClick={onClicks} /> : null}
          {showZiekenuis ? <Ziekenhuis
            onClick={onClicks}
            onUpdate={(self) => {
              setReady(true)
              console.log("OnUPDATE")
              // setRepositionCamera(true);
            }} /> : null}
        </Suspense>
        <ambientLight intensity={0.4} />
        <pointLight intensity={0.4} position={[0, 1000, 0]} />
        {/* <Sky
          exposure={0}
          distance={1000}
          sunPosition={[500, 1000, 0]}
          inclination={0}
          azimuth={-180}
          // mieCoefficient={0.005}
          // mieDirectionalG={0.8}
          rayleigh={4}
          turbidity={1}
          elevation={1}
          {...props} /> */}
        <Controls zoom={zoom} focus={focus} doanimate={false} />
        {ready ? <Animations /> : null}
        <RepositionCamera />
        {/* <OrbitControls /> */}
      </ThreeCanvas>
      <Loader />
    </>
  )
}
export default Canvas;