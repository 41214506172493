/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useThree, useFrame } from '@react-three/fiber'
import { useGLTF } from '@react-three/drei'

// function ZoomIn() {
//   const { camera, gl, controls } = useThree()
//   let didUpdate = false;
//   camera.position.x = 81.4068954
//   camera.position.y = 30.46976
//   camera.position.z = 1.84068

//   camera.rotation.x = -1.09964
//   camera.rotation.y = 1.24899
//   camera.rotation.z = 1.07799
//   camera.updateProjectionMatrix()
//   return null;
//   // return useFrame(({ clock, camera }) => {
//   //   // camera.position.z = 50 + Math.sin(clock.getElapsedTime()) * 30
//   //   if (!didUpdate) {
//   //     camera.position.x = 81.4068954
//   //     camera.position.y = 30.46976
//   //     camera.position.z = 1.84068

//   //     camera.rotation.x = -1.09964
//   //     camera.rotation.y = 1.24899
//   //     camera.rotation.z = 1.07799
//   //     didUpdate = true;
//   //   }
//   // })
// }

export default function Model({ ...props }) {
  const { camera, gl, controls } = useThree()
  camera.userData.sceneReposition = 'ziekenhuis';
  const group = useRef()
  const { state, scene, nodes, materials } = useGLTF('assets/Care_center.glb')
  // const controls = useMemo(() => new CameraControls(camera, gl.domElement), [])

  // group.onUpdate = () => {
  //   camera.position.x = 81.4068954
  //   camera.position.y = 30.46976
  //   camera.position.z = 1.84068

  //   camera.rotation.x = -1.09964
  //   camera.rotation.y = 1.24899
  //   camera.rotation.z = 1.07799
  //   camera.updateProjectionMatrix()
  // }
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene" userData={{ M3: {}, carver_target: { name: 'Plane.001', type: 'Object' } }}>
        <mesh
          name="Care_center"
          geometry={nodes.Care_center.geometry}
          material={materials['Care center']}
          position={[-1.4, 22.8, 1.45]}
          userData={{ name: 'Care center' }}
        />
        <mesh
          name="Center005"
          geometry={nodes.Center005.geometry}
          material={nodes.Center005.material}
          position={[-1.78, 13.08, 0.41]}
          userData={{ name: 'Center.005' }}
        />
        <mesh
          name="Center007"
          geometry={nodes.Center007.geometry}
          material={nodes.Center007.material}
          position={[-0.13, 30.38, 0.41]}
          userData={{ name: 'Center.007' }}
        />
        <mesh
          name="Center022"
          geometry={nodes.Center022.geometry}
          material={nodes.Center022.material}
          position={[0, 11.13, 0.41]}
          userData={{ name: 'Center.022' }}
        />
        <group name="Picture001" position={[-35.87, 22.93, 18.03]} userData={{ name: 'Picture.001' }}>
          <mesh name="Plane028" geometry={nodes.Plane028.geometry} material={nodes.Plane028.material} />
          <mesh name="Plane028_1" geometry={nodes.Plane028_1.geometry} material={materials['Cuadro 2']} />
        </group>
        <mesh
          name="Picture"
          geometry={nodes.Picture.geometry}
          material={materials['Cuadro 3 imagen']}
          position={[-35.87, 22.93, -16.97]}
          userData={{ name: 'Picture' }}
        />
        <group name="Center025" position={[27.05, 5.06, 32.43]} userData={{ name: 'Center.025' }}>
          <mesh name="Plane030" geometry={nodes.Plane030.geometry} material={nodes.Plane030.material} />
          <mesh name="Plane030_1" geometry={nodes.Plane030_1.geometry} material={materials['Imagen Cuadro 4']} />
        </group>
        <group name="Center026" position={[27.05, 5.06, -31.52]} userData={{ name: 'Center.026' }}>
          <mesh name="Plane031" geometry={nodes.Plane031.geometry} material={nodes.Plane031.material} />
          <mesh name="Plane031_1" geometry={nodes.Plane031_1.geometry} material={materials.Cuadro} />
        </group>
        <group name="Door" position={[0.41, 19.49, 0.41]} userData={{ name: 'Door' }}>
          <mesh name="Plane033" geometry={nodes.Plane033.geometry} material={nodes.Plane033.material} />
          <mesh name="Plane033_1" geometry={nodes.Plane033_1.geometry} material={nodes.Plane033_1.material} />
        </group>
        <mesh
          name="Cube003"
          geometry={nodes.Cube003.geometry}
          material={nodes.Cube003.material}
          position={[-0.01, 36.35, 0.35]}
          userData={{ name: 'Cube.003' }}
        />
        <mesh
          name="Cube005"
          geometry={nodes.Cube005.geometry}
          material={nodes.Cube005.material}
          position={[-31.1, 2.1, -16.32]}
          userData={{ name: 'Cube.005' }}
        />
        <group name="Cube006" position={[22.43, 15.23, 0.41]} userData={{ name: 'Cube.006' }}>
          <mesh name="Cube023_1" geometry={nodes.Cube023_1.geometry} material={nodes.Cube023_1.material} />
          <mesh name="Cube023_2" geometry={nodes.Cube023_2.geometry} material={nodes.Cube023_2.material} />
        </group>
        <mesh
          name="Cube019"
          geometry={nodes.Cube019.geometry}
          material={nodes.Cube019.material}
          position={[20.73, 4.25, 26.64]}
          userData={{ name: 'Cube.019' }}
        />
        <mesh
          name="Cube020"
          geometry={nodes.Cube020.geometry}
          material={nodes.Cube020.material}
          position={[20.73, 3.45, 27.56]}
          userData={{ name: 'Cube.020' }}
        />
        <mesh
          name="Cube021"
          geometry={nodes.Cube021.geometry}
          material={nodes.Cube021.material}
          position={[12.54, 2.99, 27.14]}
          userData={{ name: 'Cube.021' }}
        />
        <mesh
          name="Cube022"
          geometry={nodes.Cube022.geometry}
          material={nodes.Cube022.material}
          position={[20.24, 1.82, 26.7]}
          userData={{ name: 'Cube.022' }}
        />
        <mesh
          name="Cube026"
          geometry={nodes.Cube026.geometry}
          material={nodes.Cube026.material}
          position={[12.54, 2.99, -25.86]}
          userData={{ name: 'Cube.026' }}
        />
        <mesh
          name="Carpet001"
          geometry={nodes.Carpet001.geometry}
          material={nodes.Carpet001.material}
          position={[15.2, 0, 10]}
          userData={{ name: 'Carpet.001' }}
        />
        <mesh
          name="Carpet"
          geometry={nodes.Carpet.geometry}
          material={nodes.Carpet.material}
          position={[15.2, 0, -8]}
          userData={{ name: 'Carpet' }}
        />
        <mesh
          name="Cube029"
          geometry={nodes.Cube029.geometry}
          material={nodes.Cube029.material}
          position={[1.38, 12.42, 0.41]}
          userData={{ name: 'Cube.029' }}
        />
        <mesh
          name="Cylinder005"
          geometry={nodes.Cylinder005.geometry}
          material={nodes.Cylinder005.material}
          position={[0, 17.18, 0.41]}
          userData={{ name: 'Cylinder.005' }}
        />
        <mesh
          name="Cylinder047"
          geometry={nodes.Cylinder047.geometry}
          material={nodes.Cylinder047.material}
          position={[-32.2, 15.1, 0.7]}
          userData={{ name: 'Cylinder.047' }}
        />
        <mesh
          name="Cylinder048"
          geometry={nodes.Cylinder048.geometry}
          material={nodes.Cylinder048.material}
          position={[15.24, 0.69, 9.98]}
          userData={{ name: 'Cylinder.048' }}
        />
        <mesh
          name="Cylinder049"
          geometry={nodes.Cylinder049.geometry}
          material={nodes.Cylinder049.material}
          position={[13.24, 1.07, 7.63]}
          userData={{ name: 'Cylinder.049' }}
        />
        <mesh
          name="Cylinder050"
          geometry={nodes.Cylinder050.geometry}
          material={nodes.Cylinder050.material}
          position={[20.77, 2.76, 30.55]}
          userData={{ name: 'Cylinder.050' }}
        />
        <mesh
          name="Cylinder051"
          geometry={nodes.Cylinder051.geometry}
          material={nodes.Cylinder051.material}
          position={[12.9, 1.52, 5.93]}
          userData={{ name: 'Cylinder.051' }}
        />
        <mesh
          name="Cylinder052"
          geometry={nodes.Cylinder052.geometry}
          material={nodes.Cylinder052.material}
          position={[11.09, 1.52, 8.55]}
          userData={{ name: 'Cylinder.052' }}
        />
        <mesh
          name="Cylinder053"
          geometry={nodes.Cylinder053.geometry}
          material={nodes.Cylinder053.material}
          position={[15.5, 1.52, 5.76]}
          userData={{ name: 'Cylinder.053' }}
        />
        <mesh
          name="Cylinder054"
          geometry={nodes.Cylinder054.geometry}
          material={nodes.Cylinder054.material}
          position={[17.19, 1.07, 12.41]}
          userData={{ name: 'Cylinder.054' }}
        />
        <mesh
          name="Cylinder055"
          geometry={nodes.Cylinder055.geometry}
          material={nodes.Cylinder055.material}
          position={[17.54, 1.52, 14.1]}
          userData={{ name: 'Cylinder.055' }}
        />
        <mesh
          name="Cylinder056"
          geometry={nodes.Cylinder056.geometry}
          material={nodes.Cylinder056.material}
          position={[19.35, 1.52, 11.48]}
          userData={{ name: 'Cylinder.056' }}
        />
        <mesh
          name="Cylinder057"
          geometry={nodes.Cylinder057.geometry}
          material={nodes.Cylinder057.material}
          position={[14.93, 1.52, 14.27]}
          userData={{ name: 'Cylinder.057' }}
        />
        <group name="Cylinder058" position={[-10.41, 4.8, 0.41]} userData={{ name: 'Cylinder.058' }}>
          <mesh name="Cylinder076" geometry={nodes.Cylinder076.geometry} material={nodes.Cylinder076.material} />
          <mesh name="Cylinder076_1" geometry={nodes.Cylinder076_1.geometry} material={nodes.Cylinder076_1.material} />
        </group>
        <mesh
          name="Cylinder063"
          geometry={nodes.Cylinder063.geometry}
          material={nodes.Cylinder063.material}
          position={[-16.89, 3.14, 24.92]}
          userData={{ name: 'Cylinder.063' }}
        />
        <mesh
          name="Cylinder065"
          geometry={nodes.Cylinder065.geometry}
          material={nodes.Cylinder065.material}
          position={[15.2, 0.64, -8.01]}
          userData={{ name: 'Cylinder.065' }}
        />
        <mesh
          name="Cylinder069"
          geometry={nodes.Cylinder069.geometry}
          material={nodes.Cylinder069.material}
          position={[11.85, 1.36, -6.79]}
          userData={{ name: 'Cylinder.069' }}
        />
        <mesh
          name="Cylinder070"
          geometry={nodes.Cylinder070.geometry}
          material={nodes.Cylinder070.material}
          position={[18.61, 1.36, -9.18]}
          userData={{ name: 'Cylinder.070' }}
        />
        <mesh
          name="Cylinder078"
          geometry={nodes.Cylinder078.geometry}
          material={nodes.Cylinder078.material}
          position={[-19.47, 1.36, -17.45]}
          userData={{ name: 'Cylinder.078' }}
        />
        <mesh
          name="Cylinder081"
          geometry={nodes.Cylinder081.geometry}
          material={nodes.Cylinder081.material}
          position={[-18.41, 1.36, 16.64]}
          userData={{ name: 'Cylinder.081' }}
        />
        <group name="Object001" position={[-5.75, 7.48, 0.41]} userData={{ name: 'Object.001' }}>
          <mesh name="Cube018" geometry={nodes.Cube018.geometry} material={nodes.Cube018.material} />
          <mesh name="Cube018_1" geometry={nodes.Cube018_1.geometry} material={nodes.Cube018_1.material} />
        </group>
        <mesh
          name="Planta005"
          geometry={nodes.Planta005.geometry}
          material={nodes.Planta005.material}
          position={[15.26, 1.45, 9.96]}
          userData={{ name: 'Planta.005' }}
        />
        <mesh
          name="Planta006"
          geometry={nodes.Planta006.geometry}
          material={nodes.Planta006.material}
          position={[15.14, 1.45, -8.1]}
          userData={{ name: 'Planta.006' }}
        />
        <mesh
          name="Planta007"
          geometry={nodes.Planta007.geometry}
          material={nodes.Planta007.material}
          position={[23.53, 3.92, 26.49]}
          userData={{ name: 'Planta.007' }}
        />
        <mesh
          name="Planta009"
          geometry={nodes.Planta009.geometry}
          material={nodes.Planta009.material}
          position={[-13.93, 1.67, 0.07]}
          userData={{ name: 'Planta.009' }}
        />
        <group name="Cube031" position={[-19.91, 14.83, 0.36]} userData={{ name: 'Cube.031' }}>
          <mesh name="Cube058" geometry={nodes.Cube058.geometry} material={nodes.Cube058.material} />
          <mesh name="Cube058_1" geometry={nodes.Cube058_1.geometry} material={nodes.Cube058_1.material} />
        </group>
        <mesh
          name="Cube027"
          geometry={nodes.Cube027.geometry}
          material={nodes.Cube027.material}
          position={[-39.61, 6.57, 0.35]}
          userData={{ name: 'Cube.027' }}
        />
        <mesh
          name="Planta011"
          geometry={nodes.Planta011.geometry}
          material={nodes.Planta011.material}
          position={[17.81, 3.92, 26.49]}
          userData={{ name: 'Planta.011' }}
        />
        <mesh
          name="Pantalla"
          geometry={nodes.Pantalla.geometry}
          material={nodes.Pantalla.material}
          position={[20.54, 4.25, -25.99]}
          userData={{ name: 'Pantalla' }}
        />
        <mesh
          name="Teclado"
          geometry={nodes.Teclado.geometry}
          material={nodes.Teclado.material}
          position={[20.54, 3.45, -26.9]}
          userData={{ name: 'Teclado' }}
        />
        <mesh
          name="Cube024"
          geometry={nodes.Cube024.geometry}
          material={nodes.Cube024.material}
          position={[21.03, 1.82, -26.04]}
          userData={{ name: 'Cube.024' }}
        />
        <mesh
          name="Cylinder004"
          geometry={nodes.Cylinder004.geometry}
          material={nodes.Cylinder004.material}
          position={[20.5, 2.76, -29.89]}
          userData={{ name: 'Cylinder.004' }}
        />
        <mesh
          name="Planta"
          geometry={nodes.Planta.geometry}
          material={nodes.Planta.material}
          position={[17.74, 3.92, -25.83]}
          userData={{ name: 'Planta' }}
        />
        <mesh
          name="Planta012"
          geometry={nodes.Planta012.geometry}
          material={nodes.Planta012.material}
          position={[23.45, 3.92, -25.83]}
          userData={{ name: 'Planta.012' }}
        />
        <mesh
          name="Liver_Island_Logo"
          geometry={nodes.Liver_Island_Logo.geometry}
          material={nodes.Liver_Island_Logo.material}
          position={[14.69, 6.53, -31.44]}
          userData={{ name: 'Liver Island Logo' }}
        />
        <mesh
          name="Liver_Island_Logo001"
          geometry={nodes.Liver_Island_Logo001.geometry}
          material={nodes.Liver_Island_Logo001.material}
          position={[14.69, 6.53, 32.36]}
          userData={{ name: 'Liver Island Logo.001' }}
        />
        <mesh
          name="Planta008"
          geometry={nodes.Planta008.geometry}
          material={nodes.Planta008.material}
          position={[-17.47, 1.67, 28.33]}
          userData={{ name: 'Planta.008' }}
        />
        <mesh
          name="Planta013"
          geometry={nodes.Planta013.geometry}
          material={nodes.Planta013.material}
          position={[-17.47, 1.67, -27.41]}
          userData={{ name: 'Planta.013' }}
        />
        <mesh
          name="Cylinder006"
          geometry={nodes.Cylinder006.geometry}
          material={nodes.Cylinder006.material}
          position={[-4.3, 2.76, 30.55]}
          userData={{ name: 'Cylinder.006' }}
        />
        <mesh
          name="Cylinder007"
          geometry={nodes.Cylinder007.geometry}
          material={nodes.Cylinder007.material}
          position={[-4.53, 2.76, -28.64]}
          userData={{ name: 'Cylinder.007' }}
        />
        <mesh
          name="Pantalla001"
          geometry={nodes.Pantalla001.geometry}
          material={nodes.Pantalla001.material}
          position={[-4.98, 3.58, -25.73]}
          userData={{ name: 'Pantalla.001' }}
        />
        <mesh
          name="Teclado001"
          geometry={nodes.Teclado001.geometry}
          material={nodes.Teclado001.material}
          position={[-4.98, 2.84, -26.64]}
          userData={{ name: 'Teclado.001' }}
        />
        <mesh
          name="Pantalla002"
          geometry={nodes.Pantalla002.geometry}
          material={nodes.Pantalla002.material}
          position={[-3.11, 3.58, 27.79]}
          userData={{ name: 'Pantalla.002' }}
        />
        <mesh
          name="Teclado002"
          geometry={nodes.Teclado002.geometry}
          material={nodes.Teclado002.material}
          position={[-4.21, 2.84, 28.64]}
          userData={{ name: 'Teclado.002' }}
        />
        <mesh
          name="Text"
          geometry={nodes.Text.geometry}
          material={nodes.Text.material}
          position={[-28.51, 29.03, -0.78]}
          userData={{ name: 'Text' }}
        />
        <group name="Rollup_Banenr" position={[-18.55, 2.59, 21.71]} userData={{ name: 'Roll.up Banenr' }}>
          <mesh name="Cube_1" geometry={nodes.Cube_1.geometry} material={nodes.Cube_1.material} />
          <mesh name="Cube_2" geometry={nodes.Cube_2.geometry} material={materials['Fibrosccam 2']} />
          <mesh name="Cube_3" geometry={nodes.Cube_3.geometry} material={nodes.Cube_3.material} />
        </group>
        <group name="Fibroscam" position={[2.78, 1.1, 26.25]} userData={{ name: 'Fibroscam' }}>
          <mesh name="Cube002" geometry={nodes.Cube002.geometry} material={nodes.Cube002.material} />
          <mesh name="Cube002_1" geometry={nodes.Cube002_1.geometry} material={nodes.Cube002_1.material} />
        </group>
        <mesh
          name="Bed"
          geometry={nodes.Bed.geometry}
          material={nodes.Bed.material}
          position={[-14.67, 2.64, 40.14]}
          userData={{ name: 'Bed' }}
        />
        <mesh
          name="Cube"
          geometry={nodes.Cube.geometry}
          material={nodes.Cube.material}
          position={[-19.22, 2.79, 34.7]}
          userData={{ name: 'Cube' }}
        />
        <mesh
          name="Bed001"
          geometry={nodes.Bed001.geometry}
          material={nodes.Bed001.material}
          position={[-4.57, 1.61, 42.71]}
          userData={{ name: 'Bed.001' }}
        />
        <mesh
          name="Cube007"
          geometry={nodes.Cube007.geometry}
          material={nodes.Cube007.material}
          position={[-3.69, 1.76, 27.59]}
          userData={{ name: 'Cube.007' }}
        />
        <group name="Rollup_Banenr002" position={[1.77, 15.79, 30.37]} userData={{ name: 'Roll.up Banenr.002' }}>
          <mesh name="Cube020_1" geometry={nodes.Cube020_1.geometry} material={nodes.Cube020_1.material} />
          <mesh name="Cube020_2" geometry={nodes.Cube020_2.geometry} material={nodes.Cube020_2.material} />
        </group>
        <group name="Rollup_Banenr003" position={[-21.19, 15.79, 30.37]} userData={{ name: 'Roll.up Banenr.003' }}>
          <mesh name="Cube021_1" geometry={nodes.Cube021_1.geometry} material={nodes.Cube021_1.material} />
          <mesh name="Cube021_2" geometry={nodes.Cube021_2.geometry} material={nodes.Cube021_2.material} />
        </group>
        <mesh
          name="Bed002"
          geometry={nodes.Bed002.geometry}
          material={nodes.Bed002.material}
          position={[5.56, 16.66, 42.82]}
          userData={{ name: 'Bed.002' }}
        />
        <mesh
          name="Cube023"
          geometry={nodes.Cube023.geometry}
          material={nodes.Cube023.material}
          position={[1.76, 16.6, 36.9]}
          userData={{ name: 'Cube.023' }}
        />
        <mesh
          name="Bed003"
          geometry={nodes.Bed003.geometry}
          material={nodes.Bed003.material}
          position={[16.45, 15.18, 41.29]}
          userData={{ name: 'Bed.003' }}
        />
        <mesh
          name="Bed004"
          geometry={nodes.Bed004.geometry}
          material={nodes.Bed004.material}
          position={[-14.38, 16.66, 42.59]}
          userData={{ name: 'Bed.004' }}
        />
        <mesh
          name="Cube025"
          geometry={nodes.Cube025.geometry}
          material={nodes.Cube025.material}
          position={[-18.18, 16.6, 36.67]}
          userData={{ name: 'Cube.025' }}
        />
        <mesh
          name="Bed005"
          geometry={nodes.Bed005.geometry}
          material={nodes.Bed005.material}
          position={[-4.17, 15.18, 41.06]}
          userData={{ name: 'Bed.005' }}
        />
        <mesh
          name="Bed006"
          geometry={nodes.Bed006.geometry}
          material={nodes.Bed006.material}
          position={[-34.44, 16.66, 42.71]}
          userData={{ name: 'Bed.006' }}
        />
        <mesh
          name="Cube028"
          geometry={nodes.Cube028.geometry}
          material={nodes.Cube028.material}
          position={[-38.24, 16.6, 36.78]}
          userData={{ name: 'Cube.028' }}
        />
        <mesh
          name="Bed007"
          geometry={nodes.Bed007.geometry}
          material={nodes.Bed007.material}
          position={[-24.34, 15.18, 40.95]}
          userData={{ name: 'Bed.007' }}
        />
        <mesh
          name="Bed008"
          geometry={nodes.Bed008.geometry}
          material={nodes.Bed008.material}
          position={[-33.78, 16.66, -36.54]}
          userData={{ name: 'Bed.008' }}
        />
        <mesh
          name="Cube032"
          geometry={nodes.Cube032.geometry}
          material={nodes.Cube032.material}
          position={[-37.58, 16.6, -42.46]}
          userData={{ name: 'Cube.032' }}
        />
        <mesh
          name="Bed009"
          geometry={nodes.Bed009.geometry}
          material={nodes.Bed009.material}
          position={[-23.69, 15.18, -38.3]}
          userData={{ name: 'Bed.009' }}
        />
        <mesh
          name="Bed010"
          geometry={nodes.Bed010.geometry}
          material={nodes.Bed010.material}
          position={[-13.93, 16.66, -37.36]}
          userData={{ name: 'Bed.010' }}
        />
        <mesh
          name="Cube033"
          geometry={nodes.Cube033.geometry}
          material={nodes.Cube033.material}
          position={[-17.73, 16.6, -43.28]}
          userData={{ name: 'Cube.033' }}
        />
        <mesh
          name="Bed011"
          geometry={nodes.Bed011.geometry}
          material={nodes.Bed011.material}
          position={[-3.83, 15.18, -39.12]}
          userData={{ name: 'Bed.011' }}
        />
        <mesh
          name="Bed012"
          geometry={nodes.Bed012.geometry}
          material={nodes.Bed012.material}
          position={[6.25, 16.66, -36.54]}
          userData={{ name: 'Bed.012' }}
        />
        <mesh
          name="Cube034"
          geometry={nodes.Cube034.geometry}
          material={nodes.Cube034.material}
          position={[2.45, 16.6, -42.46]}
          userData={{ name: 'Cube.034' }}
        />
        <mesh
          name="Bed013"
          geometry={nodes.Bed013.geometry}
          material={nodes.Bed013.material}
          position={[16.35, 15.18, -38.3]}
          userData={{ name: 'Bed.013' }}
        />
        <mesh
          name="Bed014"
          geometry={nodes.Bed014.geometry}
          material={nodes.Bed014.material}
          position={[26.11, 16.66, -36.21]}
          userData={{ name: 'Bed.014' }}
        />
        <mesh
          name="Cube035"
          geometry={nodes.Cube035.geometry}
          material={nodes.Cube035.material}
          position={[22.31, 16.6, -42.13]}
          userData={{ name: 'Cube.035' }}
        />
        <mesh
          name="Bed015"
          geometry={nodes.Bed015.geometry}
          material={nodes.Bed015.material}
          position={[36.2, 15.18, -37.97]}
          userData={{ name: 'Bed.015' }}
        />
        <mesh
          name="Cube036"
          geometry={nodes.Cube036.geometry}
          material={nodes.Cube036.material}
          position={[-5.12, 1.76, -26.1]}
          userData={{ name: 'Cube.036' }}
        />
        <group name="Fibroscam001" position={[-19.1, 4.37, 33.79]} userData={{ name: 'Fibroscam.001' }}>
          <mesh name="Cube065" geometry={nodes.Cube065.geometry} material={nodes.Cube065.material} />
          <mesh name="Cube065_1" geometry={nodes.Cube065_1.geometry} material={nodes.Cube065_1.material} />
        </group>
        <group name="Rollup_Banenr004" position={[34.38, 15.79, 30.37]} userData={{ name: 'Roll.up Banenr.004' }}>
          <mesh name="Cube001" geometry={nodes.Cube001.geometry} material={nodes.Cube001.material} />
          <mesh name="Cube001_1" geometry={nodes.Cube001_1.geometry} material={nodes.Cube001_1.material} />
        </group>
        <group name="Fibroscam002" position={[-14.64, 1.1, 26.41]} userData={{ name: 'Fibroscam.002' }}>
          <mesh name="Cube003_1" geometry={nodes.Cube003_1.geometry} material={nodes.Cube003_1.material} />
          <mesh name="Cube003_2" geometry={nodes.Cube003_2.geometry} material={nodes.Cube003_2.material} />
        </group>
        <mesh
          name="Bed016"
          geometry={nodes.Bed016.geometry}
          material={nodes.Bed016.material}
          position={[25.28, 16.66, 42.82]}
          userData={{ name: 'Bed.016' }}
        />
        <mesh
          name="Cube037"
          geometry={nodes.Cube037.geometry}
          material={nodes.Cube037.material}
          position={[21.48, 16.6, 36.9]}
          userData={{ name: 'Cube.037' }}
        />
        <mesh
          name="Bed017"
          geometry={nodes.Bed017.geometry}
          material={nodes.Bed017.material}
          position={[36.17, 15.18, 41.29]}
          userData={{ name: 'Bed.017' }}
        />
        <mesh
          name="Fibro_scam_logo"
          geometry={nodes.Fibro_scam_logo.geometry}
          material={nodes.Fibro_scam_logo.material}
          position={[-11.44, 7.73, 23.31]}
          userData={{ name: 'Fibro scam logo' }}
        />
      </group>
    </group>
  )
}

useGLTF.preload('/Care_center.glb')
