import React from "react";

import { Container } from 'react-bootstrap';

const HtmlOverlay = (props) => {

  return (
    <>
      <Container
        id="overlayContainer"
        fluid style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: 300,
          height: '100vh',
          zIndex: 1,
        }} />
      <pre
        id="overlayDebug"
        className="p-1"
        style={{ fontSize: 10, backgroundColor: 'black !important' }}
        onClick={(evt) => {
          alert(JSON.stringify(evt.target.innerText, null, 2))
        }}
      >
        {"{}"}
      </pre>
    </>
  )
}
export default HtmlOverlay;