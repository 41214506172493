/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function Model({ ...props }) {
  const group = useRef()
  const { nodes, materials } = useGLTF('assets/Cinema.glb')
  return (
    <group ref={group} {...props} dispose={null}>
      <group
        name="Scene"
        userData={{ glTF2ExportSettings: { export_extras: 1, export_format: 'GLB', use_selection: 1 } }}>
        <mesh
          name="Cinema"
          geometry={nodes.Cinema.geometry}
          material={nodes.Cinema.material}
          position={[-0.51, 2.04, -1.21]}
          userData={{ name: 'Cinema' }}
        />
        <mesh
          name="Cinema001"
          geometry={nodes.Cinema001.geometry}
          material={nodes.Cinema001.material}
          position={[0.89, 0.4, -2.3]}
          userData={{ name: 'Cinema.001' }}
        />
        <mesh
          name="Cinema002"
          geometry={nodes.Cinema002.geometry}
          material={nodes.Cinema002.material}
          position={[-1.24, 1.09, -0.75]}
          userData={{ name: 'Cinema.002' }}
        />
        <mesh
          name="Cinema003"
          geometry={nodes.Cinema003.geometry}
          material={nodes.Cinema003.material}
          position={[-1.24, 1.09, -3.75]}
          userData={{ name: 'Cinema.003' }}
        />
        <mesh
          name="Cinema005"
          geometry={nodes.Cinema005.geometry}
          material={nodes.Cinema005.material}
          position={[0.37, 0.89, -0.75]}
          userData={{ name: 'Cinema.005' }}
        />
        <mesh
          name="Cinema006"
          geometry={nodes.Cinema006.geometry}
          material={nodes.Cinema006.material}
          position={[0.37, 0.89, -3.75]}
          userData={{ name: 'Cinema.006' }}
        />
        <mesh
          name="Cinema007"
          geometry={nodes.Cinema007.geometry}
          material={nodes.Cinema007.material}
          position={[2.02, 0.54, -0.75]}
          userData={{ name: 'Cinema.007' }}
        />
        <mesh
          name="Cinema008"
          geometry={nodes.Cinema008.geometry}
          material={nodes.Cinema008.material}
          position={[2.02, 0.54, -3.75]}
          userData={{ name: 'Cinema.008' }}
        />
        <group name="Cinema009" position={[5.48, 2, -2.29]} userData={{ name: 'Cinema.009' }}>
          <mesh name="Plane012" geometry={nodes.Plane012.geometry} material={nodes.Plane012.material} />
          <mesh
            name="Plane012_1"
            geometry={nodes.Plane012_1.geometry}
            material={materials['Interavtive screem cinema']}
          />
        </group>
        <mesh
          name="Cinema010"
          geometry={nodes.Cinema010.geometry}
          material={nodes.Cinema010.material}
          position={[0.89, 0.42, -2.21]}
          userData={{ name: 'Cinema.010' }}
        />
        <mesh
          name="Cinema012"
          geometry={nodes.Cinema012.geometry}
          material={nodes.Cinema012.material}
          position={[-4.25, 0.73, -5.12]}
          userData={{ name: 'Cinema.012' }}
        />
        <mesh
          name="Cinema014"
          geometry={nodes.Cinema014.geometry}
          material={nodes.Cinema014.material}
          position={[-3.59, 2.14, -3.62]}
          userData={{ name: 'Cinema.014' }}
        />
        <mesh
          name="Cinema015"
          geometry={nodes.Cinema015.geometry}
          material={nodes.Cinema015.material}
          position={[4.51, 0.67, 1.41]}
          userData={{ name: 'Cinema.015' }}
        />
        <mesh
          name="Cinema016"
          geometry={nodes.Cinema016.geometry}
          material={nodes.Cinema016.material}
          position={[-3.91, 0.67, 1.41]}
          userData={{ name: 'Cinema.016' }}
        />
        <group name="Cinema013" position={[-4.03, 3.15, -5.6]} userData={{ name: 'Cinema.013' }}>
          <mesh name="Plane020" geometry={nodes.Plane020.geometry} material={nodes.Plane020.material} />
          <mesh name="Plane020_1" geometry={nodes.Plane020_1.geometry} material={materials['Picture 1']} />
        </group>
        <group name="Cinema004" position={[-5.51, 3.44, 0.05]} userData={{ name: 'Cinema.004' }}>
          <mesh name="Plane004" geometry={nodes.Plane004.geometry} material={nodes.Plane004.material} />
          <mesh name="Plane004_1" geometry={nodes.Plane004_1.geometry} material={materials['Picture 2']} />
        </group>
        <mesh
          name="Pilares"
          geometry={nodes.Pilares.geometry}
          material={nodes.Pilares.material}
          position={[-0.01, 2, 1.47]}
          userData={{ name: 'Pilares' }}
        />
        <mesh
          name="Cube"
          geometry={nodes.Cube.geometry}
          material={nodes.Cube.material}
          position={[1.59, 2.65, -5.63]}
          userData={{ name: 'Cube' }}
        />
        <mesh
          name="Cube001"
          geometry={nodes.Cube001.geometry}
          material={nodes.Cube001.material}
          position={[-0.03, 0.32, 3.2]}
          userData={{ name: 'Cube.001' }}
        />
        <group name="Cube002" position={[0.01, 1.99, 1.71]} userData={{ name: 'Cube.002' }}>
          <mesh name="Cube003_1" geometry={nodes.Cube003_1.geometry} material={nodes.Cube003_1.material} />
          <mesh
            name="Cube003_2"
            geometry={nodes.Cube003_2.geometry}
            material={materials['Interactive screem popcorn 1']}
          />
          <mesh
            name="Cube003_3"
            geometry={nodes.Cube003_3.geometry}
            material={materials['Interactive screem popcorn 2']}
          />
          <mesh
            name="Cube003_4"
            geometry={nodes.Cube003_4.geometry}
            material={materials['Interactive screem popcorn 3']}
          />
          <mesh
            name="Cube003_5"
            geometry={nodes.Cube003_5.geometry}
            material={materials['Interactive screem popcorn 4']}
          />
          <mesh
            name="Cube003_6"
            geometry={nodes.Cube003_6.geometry}
            material={materials['Interactive screem popcorn 5']}
          />
        </group>
        <mesh
          name="Cube003"
          geometry={nodes.Cube003.geometry}
          material={nodes.Cube003.material}
          position={[-4.96, 0.8, 4.09]}
          userData={{ name: 'Cube.003' }}
        />
        <mesh
          name="Cube004"
          geometry={nodes.Cube004.geometry}
          material={nodes.Cube004.material}
          position={[-5.4, 1.03, 5.01]}
          userData={{ name: 'Cube.004' }}
        />
        <mesh
          name="Cube005"
          geometry={nodes.Cube005.geometry}
          material={nodes.Cube005.material}
          position={[-5.4, 1.03, 4.42]}
          userData={{ name: 'Cube.005' }}
        />
        <mesh
          name="Cube006"
          geometry={nodes.Cube006.geometry}
          material={nodes.Cube006.material}
          position={[-5.4, 1.03, 3.81]}
          userData={{ name: 'Cube.006' }}
        />
        <mesh
          name="Cube007"
          geometry={nodes.Cube007.geometry}
          material={nodes.Cube007.material}
          position={[4.98, 0.8, 4.47]}
          userData={{ name: 'Cube.007' }}
        />
        <mesh
          name="Cube008"
          geometry={nodes.Cube008.geometry}
          material={nodes.Cube008.material}
          position={[5.38, 1.03, 3.65]}
          userData={{ name: 'Cube.008' }}
        />
        <mesh
          name="Cube009"
          geometry={nodes.Cube009.geometry}
          material={nodes.Cube009.material}
          position={[5.38, 1.03, 4.25]}
          userData={{ name: 'Cube.009' }}
        />
        <mesh
          name="Cube010"
          geometry={nodes.Cube010.geometry}
          material={nodes.Cube010.material}
          position={[5.38, 1.03, 4.86]}
          userData={{ name: 'Cube.010' }}
        />
        <mesh
          name="Cube011"
          geometry={nodes.Cube011.geometry}
          material={nodes.Cube011.material}
          position={[0, 0.45, 2.1]}
          userData={{ name: 'Cube.011' }}
        />
        <mesh
          name="Cube012"
          geometry={nodes.Cube012.geometry}
          material={nodes.Cube012.material}
          position={[-0.7, 1.05, 1.92]}
          userData={{ name: 'Cube.012' }}
        />
        <mesh
          name="Cylinder"
          geometry={nodes.Cylinder.geometry}
          material={nodes.Cylinder.material}
          position={[-0.67, 1.11, 1.95]}
          userData={{ name: 'Cylinder' }}
        />
        <mesh
          name="Text"
          geometry={nodes.Text.geometry}
          material={nodes.Text.material}
          position={[-0.71, 1.3, 2.14]}
          userData={{ name: 'Text' }}
        />
        <mesh
          name="Cube013"
          geometry={nodes.Cube013.geometry}
          material={nodes.Cube013.material}
          position={[0.67, 1.05, 1.92]}
          userData={{ name: 'Cube.013' }}
        />
        <mesh
          name="Cylinder001"
          geometry={nodes.Cylinder001.geometry}
          material={nodes.Cylinder001.material}
          position={[0.7, 1.11, 1.95]}
          userData={{ name: 'Cylinder.001' }}
        />
        <mesh
          name="Text001"
          geometry={nodes.Text001.geometry}
          material={nodes.Text001.material}
          position={[0.66, 1.3, 2.14]}
          userData={{ name: 'Text.001' }}
        />
        <mesh
          name="Cube014"
          geometry={nodes.Cube014.geometry}
          material={nodes.Cube014.material}
          position={[2.35, 2.75, -5.54]}
          userData={{ name: 'Cube.014' }}
        />
        <mesh
          name="Cube015"
          geometry={nodes.Cube015.geometry}
          material={nodes.Cube015.material}
          position={[-1.39, 1.09, 2.03]}
          userData={{ name: 'Cube.015' }}
        />
        <mesh
          name="Cube016"
          geometry={nodes.Cube016.geometry}
          material={nodes.Cube016.material}
          position={[1.4, 1.09, 2.03]}
          userData={{ name: 'Cube.016' }}
        />
        <mesh
          name="Cylinder002"
          geometry={nodes.Cylinder002.geometry}
          material={nodes.Cylinder002.material}
          position={[-0.24, 0.83, 1.81]}
          userData={{ name: 'Cylinder.002' }}
        />
        <mesh
          name="Cylinder003"
          geometry={nodes.Cylinder003.geometry}
          material={nodes.Cylinder003.material}
          position={[-0.24, 0.95, 1.81]}
          userData={{ name: 'Cylinder.003' }}
        />
        <mesh
          name="Cylinder004"
          geometry={nodes.Cylinder004.geometry}
          material={nodes.Cylinder004.material}
          position={[-0.24, 1.12, 1.81]}
          userData={{ name: 'Cylinder.004' }}
        />
        <mesh
          name="Cylinder005"
          geometry={nodes.Cylinder005.geometry}
          material={nodes.Cylinder005.material}
          position={[-0.24, 1.24, 1.81]}
          userData={{ name: 'Cylinder.005' }}
        />
        <mesh
          name="Cylinder006"
          geometry={nodes.Cylinder006.geometry}
          material={nodes.Cylinder006.material}
          position={[0.18, 1.03, 1.81]}
          userData={{ name: 'Cylinder.006' }}
        />
        <mesh
          name="Cylinder007"
          geometry={nodes.Cylinder007.geometry}
          material={nodes.Cylinder007.material}
          position={[0.18, 0.97, 1.81]}
          userData={{ name: 'Cylinder.007' }}
        />
        <mesh
          name="Cylinder008"
          geometry={nodes.Cylinder008.geometry}
          material={nodes.Cylinder008.material}
          position={[0.18, 0.91, 1.81]}
          userData={{ name: 'Cylinder.008' }}
        />
        <mesh
          name="Cylinder009"
          geometry={nodes.Cylinder009.geometry}
          material={nodes.Cylinder009.material}
          position={[0.18, 0.85, 1.81]}
          userData={{ name: 'Cylinder.009' }}
        />
        <mesh
          name="Cylinder010"
          geometry={nodes.Cylinder010.geometry}
          material={nodes.Cylinder010.material}
          position={[0.18, 0.8, 1.81]}
          userData={{ name: 'Cylinder.010' }}
        />
        <mesh
          name="Cylinder011"
          geometry={nodes.Cylinder011.geometry}
          material={nodes.Cylinder011.material}
          position={[0.18, 0.76, 1.81]}
          userData={{ name: 'Cylinder.011' }}
        />
        <mesh
          name="Cube018"
          geometry={nodes.Cube018.geometry}
          material={nodes.Cube018.material}
          position={[4.82, 0.61, -5.57]}
          userData={{ name: 'Cube.018' }}
        />
        <mesh
          name="Cube019"
          geometry={nodes.Cube019.geometry}
          material={nodes.Cube019.material}
          position={[-2.72, 3, -2.22]}
          userData={{ name: 'Cube.019' }}
        />
        <mesh
          name="Cylinder012"
          geometry={nodes.Cylinder012.geometry}
          material={nodes.Cylinder012.material}
          position={[-0.2, 0.83, 2.1]}
          userData={{ name: 'Cylinder.012' }}
        />
        <mesh
          name="Cylinder013"
          geometry={nodes.Cylinder013.geometry}
          material={nodes.Cylinder013.material}
          position={[-0.2, 0.95, 2.1]}
          userData={{ name: 'Cylinder.013' }}
        />
        <mesh
          name="Cylinder014"
          geometry={nodes.Cylinder014.geometry}
          material={nodes.Cylinder014.material}
          position={[-0.2, 1.12, 2.1]}
          userData={{ name: 'Cylinder.014' }}
        />
        <mesh
          name="Cylinder015"
          geometry={nodes.Cylinder015.geometry}
          material={nodes.Cylinder015.material}
          position={[-0.2, 1.24, 2.1]}
          userData={{ name: 'Cylinder.015' }}
        />
        <mesh
          name="Cylinder016"
          geometry={nodes.Cylinder016.geometry}
          material={nodes.Cylinder016.material}
          position={[0.18, 1.03, 1.97]}
          userData={{ name: 'Cylinder.016' }}
        />
        <mesh
          name="Cylinder017"
          geometry={nodes.Cylinder017.geometry}
          material={nodes.Cylinder017.material}
          position={[0.18, 0.97, 1.97]}
          userData={{ name: 'Cylinder.017' }}
        />
        <mesh
          name="Cylinder018"
          geometry={nodes.Cylinder018.geometry}
          material={nodes.Cylinder018.material}
          position={[0.18, 0.91, 1.97]}
          userData={{ name: 'Cylinder.018' }}
        />
        <mesh
          name="Cylinder019"
          geometry={nodes.Cylinder019.geometry}
          material={nodes.Cylinder019.material}
          position={[0.18, 0.85, 1.97]}
          userData={{ name: 'Cylinder.019' }}
        />
        <mesh
          name="Cylinder020"
          geometry={nodes.Cylinder020.geometry}
          material={nodes.Cylinder020.material}
          position={[0.18, 0.8, 1.97]}
          userData={{ name: 'Cylinder.020' }}
        />
        <mesh
          name="Cylinder021"
          geometry={nodes.Cylinder021.geometry}
          material={nodes.Cylinder021.material}
          position={[0.18, 0.76, 1.97]}
          userData={{ name: 'Cylinder.021' }}
        />
        <mesh
          name="Cylinder022"
          geometry={nodes.Cylinder022.geometry}
          material={nodes.Cylinder022.material}
          position={[0.18, 1.03, 2.14]}
          userData={{ name: 'Cylinder.022' }}
        />
        <mesh
          name="Cylinder023"
          geometry={nodes.Cylinder023.geometry}
          material={nodes.Cylinder023.material}
          position={[0.18, 0.97, 2.14]}
          userData={{ name: 'Cylinder.023' }}
        />
        <mesh
          name="Cylinder024"
          geometry={nodes.Cylinder024.geometry}
          material={nodes.Cylinder024.material}
          position={[0.18, 0.91, 2.14]}
          userData={{ name: 'Cylinder.024' }}
        />
        <mesh
          name="Cylinder025"
          geometry={nodes.Cylinder025.geometry}
          material={nodes.Cylinder025.material}
          position={[0.18, 0.85, 2.14]}
          userData={{ name: 'Cylinder.025' }}
        />
        <mesh
          name="Cylinder026"
          geometry={nodes.Cylinder026.geometry}
          material={nodes.Cylinder026.material}
          position={[0.18, 0.8, 2.14]}
          userData={{ name: 'Cylinder.026' }}
        />
        <mesh
          name="Cylinder027"
          geometry={nodes.Cylinder027.geometry}
          material={nodes.Cylinder027.material}
          position={[0.18, 0.76, 2.14]}
          userData={{ name: 'Cylinder.027' }}
        />
        <mesh
          name="Cube024"
          geometry={nodes.Cube024.geometry}
          material={nodes.Cube024.material}
          position={[-2.94, 2.52, -2.22]}
          userData={{ name: 'Cube.024' }}
        />
        <mesh
          name="Cylinder028"
          geometry={nodes.Cylinder028.geometry}
          material={nodes.Cylinder028.material}
          position={[-2.95, 0.32, 1.79]}
          userData={{ name: 'Cylinder.028' }}
        />
        <mesh
          name="Cylinder029"
          geometry={nodes.Cylinder029.geometry}
          material={nodes.Cylinder029.material}
          position={[5.37, 0.32, 1.91]}
          userData={{ name: 'Cylinder.029' }}
        />
        <mesh
          name="Cylinder030"
          geometry={nodes.Cylinder030.geometry}
          material={nodes.Cylinder030.material}
          position={[5.3, 0.32, 0.91]}
          userData={{ name: 'Cylinder.030' }}
        />
        <mesh
          name="Cube025"
          geometry={nodes.Cube025.geometry}
          material={nodes.Cube025.material}
          position={[-4.48, 2.98, -3.93]}
          userData={{ name: 'Cube.025' }}
        />
        <mesh
          name="Cube026"
          geometry={nodes.Cube026.geometry}
          material={nodes.Cube026.material}
          position={[-4.46, 3.74, -3.57]}
          userData={{ name: 'Cube.026' }}
        />
        <mesh
          name="Cube027"
          geometry={nodes.Cube027.geometry}
          material={nodes.Cube027.material}
          position={[-4.46, 3.34, -3.57]}
          userData={{ name: 'Cube.027' }}
        />
        <mesh
          name="Cube028"
          geometry={nodes.Cube028.geometry}
          material={nodes.Cube028.material}
          position={[-4.46, 2.94, -3.57]}
          userData={{ name: 'Cube.028' }}
        />
        <mesh
          name="Cube029"
          geometry={nodes.Cube029.geometry}
          material={nodes.Cube029.material}
          position={[-4.46, 2.54, -3.57]}
          userData={{ name: 'Cube.029' }}
        />
        <mesh
          name="Cube030"
          geometry={nodes.Cube030.geometry}
          material={nodes.Cube030.material}
          position={[-4.46, 2.14, -3.57]}
          userData={{ name: 'Cube.030' }}
        />
        <mesh
          name="Cube031"
          geometry={nodes.Cube031.geometry}
          material={nodes.Cube031.material}
          position={[-2.76, 2.98, -0.84]}
          userData={{ name: 'Cube.031' }}
        />
        <mesh
          name="Cube032"
          geometry={nodes.Cube032.geometry}
          material={nodes.Cube032.material}
          position={[-3.11, 3.74, -0.82]}
          userData={{ name: 'Cube.032' }}
        />
        <mesh
          name="Cube033"
          geometry={nodes.Cube033.geometry}
          material={nodes.Cube033.material}
          position={[-3.11, 3.34, -0.82]}
          userData={{ name: 'Cube.033' }}
        />
        <mesh
          name="Cube034"
          geometry={nodes.Cube034.geometry}
          material={nodes.Cube034.material}
          position={[-3.11, 2.94, -0.82]}
          userData={{ name: 'Cube.034' }}
        />
        <mesh
          name="Cube035"
          geometry={nodes.Cube035.geometry}
          material={nodes.Cube035.material}
          position={[-3.11, 2.54, -0.82]}
          userData={{ name: 'Cube.035' }}
        />
        <mesh
          name="Cube036"
          geometry={nodes.Cube036.geometry}
          material={nodes.Cube036.material}
          position={[-3.11, 2.14, -0.82]}
          userData={{ name: 'Cube.036' }}
        />
        <mesh
          name="Cube037"
          geometry={nodes.Cube037.geometry}
          material={nodes.Cube037.material}
          position={[-2.76, 2.98, 0.36]}
          userData={{ name: 'Cube.037' }}
        />
        <mesh
          name="Cube038"
          geometry={nodes.Cube038.geometry}
          material={nodes.Cube038.material}
          position={[-3.11, 3.74, 0.38]}
          userData={{ name: 'Cube.038' }}
        />
        <mesh
          name="Cube039"
          geometry={nodes.Cube039.geometry}
          material={nodes.Cube039.material}
          position={[-3.11, 3.34, 0.38]}
          userData={{ name: 'Cube.039' }}
        />
        <mesh
          name="Cube040"
          geometry={nodes.Cube040.geometry}
          material={nodes.Cube040.material}
          position={[-3.11, 2.94, 0.38]}
          userData={{ name: 'Cube.040' }}
        />
        <mesh
          name="Cube041"
          geometry={nodes.Cube041.geometry}
          material={nodes.Cube041.material}
          position={[-3.11, 2.54, 0.38]}
          userData={{ name: 'Cube.041' }}
        />
        <mesh
          name="Cube042"
          geometry={nodes.Cube042.geometry}
          material={nodes.Cube042.material}
          position={[-3.11, 2.14, 0.38]}
          userData={{ name: 'Cube.042' }}
        />
        <mesh
          name="Cube043"
          geometry={nodes.Cube043.geometry}
          material={nodes.Cube043.material}
          position={[-5.03, 3, 0.18]}
          userData={{ name: 'Cube.043' }}
        />
        <mesh
          name="Cube044"
          geometry={nodes.Cube044.geometry}
          material={nodes.Cube044.material}
          position={[-5.1, 2.52, 0.39]}
          userData={{ name: 'Cube.044' }}
        />
        <mesh
          name="Cylinder061"
          geometry={nodes.Cylinder061.geometry}
          material={nodes.Cylinder061.material}
          position={[-5.32, 2.71, -1.81]}
          userData={{ name: 'Cylinder.061' }}
        />
        <mesh
          name="Liver_Island_Logo"
          geometry={nodes.Liver_Island_Logo.geometry}
          material={nodes.Liver_Island_Logo.material}
          position={[-5.51, 2.93, 3.75]}
          rotation={[0, Math.PI / 2, 0]}
          scale={0.8}
          userData={{ name: 'Liver Island Logo' }}
        />
        <mesh
          name="Liver_Island_Logo001"
          geometry={nodes.Liver_Island_Logo001.geometry}
          material={nodes.Liver_Island_Logo001.material}
          position={[5.52, 2.93, 3.6]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={0.8}
          userData={{ name: 'Liver Island Logo.001' }}
        />
      </group>
    </group>
  )
}

useGLTF.preload('/Cinema.glb')
