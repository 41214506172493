import $ from 'jquery'
class Clicks {
  constructor(el) {
    this.el = el;
    this.clicks = 0;
    this.overlay = document.querySelector('#overlayContainer');
    this.render();
  }

  render() {
    console.log("Clicked: ", this.el.object.name)
    // this.overlay.style.display = 'none';
    switch (this.el.object.name) {
      case 'Factory_2':
        this.swhoModal('test');
        break;
      case 'House005':
      case 'House006':
      case 'House009':
      case 'House021':
      case 'House016':
        this.swhoModal('huisje');
        break;
      case 'Cube028_1':
        this.swhoModal('bios');
        break;
      case 'Cube008':
      case 'Plane003_1':
      case 'Cube005':
        this.swhoModal('ziekenhuis');
        break;
    }
  }

  swhoModal(name) {
    this.overlay.innerHTML = `
    <div class="overlay-container">
      <div class="overlay-content">
        Loading: ${name}...
      </div>
    </div>`;
    this.overlay.style.display = 'block';
    document.querySelector('.overlay-content').style.display = 'block';
    this.fetchContent(name).then(() => {
      var scripts = [];
      var ret = document.querySelector('.overlay-content').childNodes;
      // console.log('ret', ret);
      for (var i = 0; ret[i]; i++) {
        if (scripts && this.nodeName(ret[i], "script") && (!ret[i].type || ret[i].type.toLowerCase() === "text/javascript")) {
          scripts.push(ret[i].parentNode ? ret[i].parentNode.removeChild(ret[i]) : ret[i]);
        }
      }
      // console.log(scripts)
      if (scripts.length > 0) {
        for (var script in scripts) {
          this.evalScript(scripts[script]);
        }
      }
      // var myCarousel = document.querySelector('#myCarousel')
      // var carousel = new bootstrap.Carousel(myCarousel)
      this.injectoverlay()
    })
  }

  fetchContent(name) {
    return new Promise((resolve, reject) => {
      fetch(
        global.serverURL + "/fetch.php?page=" + name
      ).then(async (response) => {
        const content = await response.text();
        // console.log(content);
        var wrapper = document.querySelector('.overlay-content');
        // wrapper.innerHTML = `<pre>${JSON.stringify(content)}</pre>`;
        wrapper.innerHTML = content;
        resolve(true);
      })
    });
  }

  nodeName(elem, name) {
    return elem.nodeName && elem.nodeName.toUpperCase() === name.toUpperCase();
  }
  evalScript(elem) {
    var data = (elem.text || elem.textContent || elem.innerHTML || "");

    var head = document.getElementsByTagName("head")[0] || document.documentElement,
      script = document.createElement("script");
    script.type = "text/javascript";
    script.appendChild(document.createTextNode(data));
    head.insertBefore(script, head.firstChild);
    head.removeChild(script);

    if (elem.parentNode) {
      elem.parentNode.removeChild(elem);
    }
  }

  injectoverlay() {
    var overlay = document.querySelector('.container')
    overlay.innerHTML += `
    <div class="close-butt-div">
      <button class="btn btn-sm btn-theme">
        Sluiten&nbsp;&nbsp;<i class="fa fa-times"></i>
      </button>
    </div>
    `
    document.querySelector('.close-butt-div').addEventListener('click', () => {
      this.overlay.innerHTML = '';
      this.overlay.style.display = 'none';
    })
  }
}
export default Clicks;